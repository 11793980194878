<template>
    <div class="about">
        <button @click="test()">click</button>
    </div>
</template>
<script type="text/javascript">
// import ntWeb3 from "@/js/ntWeb3"
export default {
    data() {
        return {
            web3: 'undefined', // 展开/收起
            ethereums: window.ethereum,
            rpcUrls: 'https://data-seed-prebsc-1-s1.binance.org:8545'
        }
    },

    created() {

        console.log(this.ethereums);


        if (typeof this.web3 !== 'undefined') {
            console.log('22222222');
            this.web3 = new this.Web3(this.web3.currentProvider);
        } else {
            // set the provider you want from Web3.providers
            console.log('11111111');
            this.web3 = new this.Web3(new this.Web3.providers.HttpProvider("http://localhost:8545"));
        }

        // this.web3 = this.Web3(this.Web3.currentProvider)
    },

    methods: {
        test() {
            console.log('12312313')

            console.log(this.web3);

            this.ethereums.request({ method: 'eth_requestAccounts' });

            // this.web3.
            // 
            this.web3.eth.getAccounts(console.log);




        },

    }

}
</script>